import React from "react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import GetDagcoins from "../../components/get-dagcoins";
import Headertransparent from "../../components/headertransparent";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import { Seo } from "../../components/seo";
import "./index.css";

function Timelinepage({ data }) {
  var dagEcotimeline = data.prismicTimeline.data.roadmap_content;

  if (data.prismicTimeline.lang === "en-us") {
    var readMore = "Read more";
  } else if (data.prismicTimeline.lang === "et-ee") {
    readMore = "Loe edasi";
  } else {
    readMore = "Read more";
  }

  var NextUid = data.prismicTimeline.alternate_languages[0].uid;

  if (data.prismicTimeline.lang === "en-us") {
    var NextUrl = "/et/" + NextUid;
  } else if (data.prismicTimeline.lang === "et-ee") {
    NextUrl = "/" + NextUid;
  } else {
    NextUrl = "wrong lang";
  }

  return (
    <Layout htmllang={data.prismicTimeline.lang}>
      <Headertransparent
        htmllang={data.prismicTimeline.lang}
        langswitcher={NextUrl}
      />
      <Hero padding={222}>
        <div className="herobg">
          <GatsbyImage
            alt="Dagcoin"
            image={data.ecobgimg.childImageSharp.gatsbyImageData}
          />
        </div>
        <PrismicRichText
          field={data.prismicTimeline.data.page_title.richText}
        />
        <a href="#more" className="btn btn--orange">
          {readMore}
        </a>
      </Hero>

      <div id="more" className="roadmap-block whitebg">
        <Container>
          <div className=" roadmap-border">
            {dagEcotimeline.map((value, index) => {
              return (
                <div className="roadmap-content" key={index}>
                  <div className="roadamp-date">{value.date.text}</div>
                  <div className="roadmap-wrap">
                    <div className="roadmap-content-wrap">
                      <PrismicRichText field={value.content.richText} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export const Head = ({ data }) => {
  const { seo_title, seo_description } = data.prismicTimeline.data;
  return (
    <>
      <Seo
        description={seo_description.text}
        pathname={data.prismicTimeline.uid}
        title={seo_title.text}
        // lang={data.prismicTimeline.lang}
      />
    </>
  );
};

export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicTimeline(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        page_title {
          text
          richText
        }
        roadmap_content {
          content {
            text
            richText
          }
          date {
            text
          }
        }
        seo_description {
          text
        }
        seo_title {
          text
        }
      }
    }

    ecobgimg: file(relativePath: { eq: "ecosystembackground.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
  }
`;
export default Timelinepage;
